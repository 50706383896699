import axios from "axios";
import {Organization} from "../pages/OrganizationsPage/Organization";
import {Sorting} from "../pages/OrganizationsPage/OrganizationsPage";

const fetchOrganizations = async (limit: number,
                                  sorting: Sorting,
                                  nextPageToken?: string | null) => {
  const params: any = {pageToken: nextPageToken, itemsPerPage: limit}

  if (sorting) {
    params['created_at'] = sorting
  }

  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/organization`,
    {
      params,
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};
const addNewOrganization = async (
  data: Pick<
    Organization,
    | "attachments"
    | "organization_name"
    | "phone"
    | "website"
    | "working_hours"
    | "comment"
    | "photos"
    | "locality_address"
    | "locality_lat"
    | "locality_long"
    | "field_of_activity"
  >
) => {
  const response = await axios.postForm(
    `${process.env.REACT_APP_BACKEND_URL}/organization`,
    {
      ...data,
    },
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      formSerializer: {
        indexes: null,
      },
    }
  );
  return response.data;
};

const updateOrganization = async (
  data: Pick<
    Organization,
    | "attachments"
    | "organization_name"
    | "phone"
    | "website"
    | "working_hours"
    | "comment"
    | "id"
    | "photos"
    | "locality"
    | "locality_address"
    | "locality_lat"
    | "locality_long"
    | "field_of_activity"
  >
) => {
  const response = await axios.putForm(
    `${process.env.REACT_APP_BACKEND_URL}/organization/${data.id}`,
    {...data},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      formSerializer: {
        indexes: null,
      },
    }
  );
  return response.data;
};

const removeOrganization = async (id: string) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_BACKEND_URL}/organization/${id}`,
    {
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};

const fetchFields = async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_BACKEND_URL}/organization/field_of_activity`,
    {
      headers: {Authorization: `Bearer ${localStorage.getItem("authToken")}`},
    }
  );
  return response.data;
};

const updateOrganizationStatus = async (
  data: Pick<Organization, "id" | "status">
) => {
  const response = await axios.put(
    `${process.env.REACT_APP_BACKEND_URL}/organization/status/${data.id}`,
    {...data},
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      formSerializer: {
        indexes: null,
      },
    }
  );
  return response.data;
};

export {
  fetchFields,
  updateOrganizationStatus,
  fetchOrganizations,
  addNewOrganization,
  removeOrganization,
  updateOrganization,
};
